import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/store'
import SubscribeForm from '@/components/SubscribeForm.vue'
import ThankYouPage from '@/components/ThankYouPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: SubscribeForm
  },
  { path: '/subscribe', component: SubscribeForm, name: 'subscribe' },
  {
    path: '/thank-you',
    name: 'ThankYouPage',
    component: ThankYouPage,
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = localStorage.getItem('access_token');
    if (token && store.state.isUserConnected) {
      console.log('Token is present and user is connected')
      // User is authenticated, proceed to the route
      next();
    } else {
      console.error('No token or user has been disconnected')
      // User is not authenticated, redirect to login
      store.commit('logout'); // Utiliser une mutation pour déconnecter l'utilisateur
      next('/login');
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});

export default router
