<template>
  <div class="min-h-screen flex flex-col items-center bg-white p-4">
    <div class="flex flex-col justify-center mt-5">
      <img src="../assets/2.png" alt="Realviews Logo" class="h-48 object-contain fill">
      <h1 class="text-3xl font-bold underline text-center mt-10">MERCI POUR TON INSCRIPTION</h1>
    </div>
    <div class="flex flex-col mt-10 justify-items-center items-center">
      <p class="text-center text-xl mt-8 w-80">Realviews sera disponible très prochainement. Tu seras parmi les premier(e)s à y avoir accès.</p>
      <p class="text-center text-xl w-80 mt-10">Reste branché(e), tu recevras ton accès en avant première par email pour ton essai gratuit.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage'
};
</script>

<style scoped>
.min-h-screen {
  min-height: 100vh;
  background-color: #fff; /* Adjust the background color if needed */
}

h1 {
  color: #2c1d54; /* Adjust the color if needed */
}

p {
  color: #4a5568; /* Adjust the color if needed */
}

.bg-white {
  background-color: #fff;
}
</style>
