<template>
    <div class="min-h-screen flex flex-col items-center justify-center bg-custom-purple">
      <div classfle="flex justify-center mb-6">
          <img src="../assets/C-7.png" alt="Realviews Logo" class="h-48">
      </div>
      <div class="bg-white p-8 rounded-lg shadow-md w-full max-w-sm flex flex-col" style="width: 600px; height: 450px;">
        <h2 class="justify-center text-center text-3xl underline font-semibold text-gray-700 mb-5 mt-5">Let's go</h2>
        <form @submit.prevent="handleSubmit" class="flex flex-col justify-between items-center h-full space-y-8">
          <div class="flex flex-col justify-center items-center flex-grow">
            <div class="mb-4">
              <label for="name" class="sr-only">Nom et prénom</label>
              <div class="relative">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" class="text-gray-400">
                    <circle cx="12" cy="12" r="12" fill="#D3D3D3"/> <!-- Gris -->
                    <path fill="#FFFFFF" d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
                </svg>
                </span>

                <input type="text" id="name" v-model="name" class="pl-10 pr-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent" 
                placeholder="Nom et prénom"
                required>
              </div>
            </div>
            <div class="">
              <label for="email" class="sr-only">Adresse Email</label>
              <div class="relative">
                <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20" class=" text-gray-400">
                    <circle cx="12" cy="12" r="12" fill="#D3D3D3"/> <!-- Gris -->
                    <path fill="#FFFFFF" d="M12 13.6L4 9V17H20V9L12 13.6ZM12 12L20 7H4L12 12ZM4 7V5H20V7L12 12L4 7Z"/>
                  </svg>
                </span>
                <input type="email" id="email" 
                v-model="email" 
                class="pl-10 pr-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent" 
                placeholder="Adresse Email"
                required>
              </div>
            </div>
          </div>
          <button type="submit" class=" w-35 h-12 mb-10 glow-button bg-purple-600 text-white p-5 rounded-full 
          shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50 
          font-semibold">INSCRIPTION
          </button>
          <div v-if="error" class="text-red-500">{{ error }}</div>
        </form>
      </div>
    </div>
        <!-- Spinner -->
    <div v-if="loading" class="fixed inset-0 flex items-center justify-center bg-white bg-opacity-95">
      <div class="bg-white p-6 rounded-lg shadow-lg">
      <div class="flex flex-col items-center">
        <div class="border-t-4 border-blue-500 w-16 h-16 rounded-full animate-spin"></div>
        <p class="text-gray-600 mt-2">{{ spinner_text }}</p>
      </div>
    </div>
    </div>
  </template>
  

<script>
import axios from 'axios';

export default {
  data() {
    return {
      name: '',
      email: '',
      loading: false,
      spinner_text: "Inscription en cours...",
      error: null
    };
  },
  methods: {
    handleSubmit() {
      this.loading = true;

      // Logic for form submission
      console.log('Name:', this.name);
      console.log('Email:', this.email);

      // You can add your email sending logic here
      axios.post(process.env.VUE_APP_ROOT_API + '/api/v1/subscription', {
        name: this.name,
        email: this.email
      }).then(() => {
        console.log('Email sent successfully');
        this.loading = false;
        this.$router.push({ name: 'ThankYouPage' });
      }).catch((error) => {
        console.error('Error:', error);
        if (error.response && error.response.status === 409) {
          this.error = "L'utilisateur existe déjà";
        }
        else {
          this.error = "Une erreur s'est produite. Veuillez réessayer plus tard";
        }
        this.loading = false;
      })
    }
  }
};
</script>

<style>
/* Optionally, add custom styles here */
.glow-button {
  display: inline-block;
  padding: 0.5em 1em;
  color: #fff;
  background-color: #6b46c1; /* Couleur de base du bouton */
  border-radius: 0.5rem; /* Ajustez selon vos besoins */
  box-shadow: 0 0 0.5em rgba(107, 70, 193, 0.5);
  position: relative;
  transition: box-shadow 0.3s ease-in-out;
}

.glow-button::before {
  content: '';
  position: absolute;
  top: -0.25em;
  left: -0.25em;
  right: -0.25em;
  bottom: -0.25em;
  background: linear-gradient(45deg, #6b46c1, #9f7aea, #b794f4, #d6bcfa, #e9d8fd, #faf5ff);
  background-size: 400%;
  border-radius: inherit;
  z-index: -1;
  filter: blur(1em);
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
}

.glow-button:hover::before {
  opacity: 1;
}

.glow-button:hover {
  box-shadow: 0 0 1em rgba(107, 70, 193, 0.75);
}
</style>
